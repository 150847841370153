@font-face {
  font-family: "Light";
  src: url(thin.ttf);
}

@font-face {
  font-family: "Bold";
  src: url(bold.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: rgb(23,0,88);
  background: linear-gradient(180deg, rgba(13,0,48,1) 0%, rgba(4,0,29,1) 61%, rgba(0,0,0,1) 100%);
}


@keyframes up-down {
  0%{
    transform: translateY(0);
  }
  50%{
    transform: translateY(-10px);
  }
  100%{
    transform: translateY(0);
  }
}

h1{
  margin: 0;
  min-height: 100vh;
  width: 100%;
  line-height: 100vh;
  text-align: center;
  color: #feca35;
  animation: up-down 1s infinite cubic-bezier(0.33, 0.01, 0.54, 0.99);
  font-family: "Bold";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
