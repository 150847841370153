@keyframes showForm {
  0%{
    transform: translateY(100px);
    opacity: 0;
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

.App {
  text-align: center;
}

.logo{
  fill: #feca35;
  stroke-width: 0px;
  margin-bottom: 20px;
}

.GigaFat{
  font-family: "Bold";
  font-size: clamp(15px, 5vw, 25px);
}

.App-header {
  background: rgb(23,0,88);
  background: linear-gradient(180deg, rgba(13,0,48,1) 0%, rgba(4,0,29,1) 61%, rgba(0,0,0,1) 100%);
  min-height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Light";
  font-size: clamp(15px, 5vw, 20px);
}

label{
  width: 100%;
  text-align: left;
}

.entrywarning, .answers{
  margin-bottom: 20px;
}

input[type="text"]{
  border: 0;
  width: calc(100% - 40px);
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.286);
  color: white;
}

textarea{
  color: #fff;
}

.submitButton{
  width: 100%;
  border-radius: 10px;
  color: #feca35;
  border: none;
  padding: 10px 5px;
  background-color: rgb(10, 9, 47);
  cursor: pointer;
  transition: scale .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.submitButton:hover{
  scale: 1.1;
  outline: #feca35 2px solid;
  outline-offset: 3px;
}

.answers{
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  margin-top: 10px;
  outline: #feca35 2px solid;
  outline-offset: 3px;
  border-radius: 10px;
  color: #fff;
}

*:focus {
  outline: none;
}

#nickname{
  border-radius: 10px;
}

.entrywarning{
  text-align: center;
}

#answer1{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.286) 0%, rgba(255,255,255,0) 100%);
  border-radius: 10px 10px 0 0 !important;
}

#answer3{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.286) 0%, rgba(255,255,255,0) 100%);
  border-radius: 0 0 10px 10px !important;
}

input{
  font-family: "Light";
  width: calc(100% - 20px);
  padding: 5px 10px;
}

.innerForm{
  max-width: 550px;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.questionLabel{
  text-align: center;
  color: #feca35;
  font-family: "Bold";
  font-size: clamp(15px, 5vw, 25px);
}

form{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  animation: showForm 1s ease;
}

@keyframes showError {
  0%{
    opacity: 0;
    transform: translateY(-100px);
  }
  15%{
    opacity: 1;
    transform: translateY(0px);
  }
  85%{
    opacity: 1;
    transform: translateY(0px);
  }
  100%{
    opacity: 0;
    transform: translateY(-100px);
  }
}

.modalWindow{
  position: fixed;
  width: calc(100% - 80px);
  display: flex;
  top: 20px;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
}

.modalWindow h3{
  animation: showError 5s ease forwards;
  background-color: rgb(0 0 0);
  padding: 10px 30px;
  margin: 0;
  font-size: clamp(7px, 5vw, 20px);
  border-radius: 20px;
}
